.card {
  background: #F7F7F7;
  border-radius: 4px;
  padding: 8px 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-collapse-header {
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: left;
  align-items: center;
}

.ant-collapse-content-box {
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}