 body {
   background-color: rgb(254, 252, 247);
 }
 
 #root {
   max-width: 384px;
   margin: auto;
   overflow-x: hidden;
   overflow-y: auto;
   background-color: #fff;
   height: 100vh;
   position: relative;
 }

 #root::-webkit-scrollbar {
  display: none;
}

 iframe {
   display: none;
 }

 @media (max-width: 768px) {
  #root {
    max-width: 100% !important;
  }
 }