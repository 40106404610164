.register {
  height: 100%;
  // background-image: url("/assets/images/login_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  &-header{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50px;
    background-image: linear-gradient(90deg,#cd0103,#f64841);
    color: #fff;
    font-size: 18px;
    margin-bottom: 30px;
  }
 &__img{
    z-index: 2;
    max-width: 28px;
    position: absolute;
    top: 12px;
    left: 12px;
  }
  &-container {
    background-color: #fff;
    // border-radius: 40px 40px 0px 0px;
    width: 100%;
    bottom: 0;
    padding: 20px 28px;

    .login__title {
      margin-top: 40px;
    }
  
    .ant-tabs-nav {
      display: none;
    }

    .ant-tabs.ant-tabs-top {
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
    }

    .login__subTitle {
      margin-bottom: 36px;
    }

    &.full {
      height: 100%;
      border-radius: 0px;
    }

    &.small {
      height: 100%;

      form {
        width: 100%;
        input {
          border: 1px solid #ad0419;
          border-radius: 8px;
          text-align: start;
          &::placeholder { 
            color: #757575;
            opacity: 1; /* Firefox */
          }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        .ant-input-password {
          border: 1px solid #ad0419;
          border-radius: none !important;

          input {
            border: none;
          }
        }
        .ant-checkbox-inner{
          width: 20px;
          height: 20px;
        }
        .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
          border-color: #ad0419;
        }
        .ant-checkbox-checked .ant-checkbox-inner{
          background-color: #ad0419;
        }
      }
    }
  }

  &__desc{
    font-size: 15px;
    color: #959595;
    margin-bottom: 15px;
  }
  &-otp {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 120px;
      height: 120px;
    }
  }

  &-agree,&-police{
    font-size: 18px;
  }

  &-police{
    margin-left: 5px;
    color: #ad0419;
  }

  &-success {
    display: flex;
    justify-content: center;
    flex-direction: column;
    img {
      width: 140px;
      height: 140px;
    }
  }

  &__button{
    margin-top: 25px;
  }

  .login{
    &-center {
      padding: 38px;
      height: auto;
    }
  }
  @media (max-height: 740.5px) {
    height: calc(100% + 150px);
  }

  @media (max-height: 667.5px) {
    height: calc(100% + 230px);
  }

  @media (max-height: 414.5px) {
    height: calc(100% + 420px);
  } 
}

.backIcon {
  svg {
    width: 24px;
    height: 24px;
    margin: 0;
  }
}
