.saving {
  &__left {
    background-color: var(--white-color);
    padding-bottom: 24px;
    &__top {
      background-color: var(--main-color);
      padding: 28px;
      p {
        margin: 0;
        padding: 0;
      }
    }
    &__wallet {
      display: inline-flex;
      align-items: center;
      margin-top: 16px;
      background: rgba(255, 255, 255, 0.12);
      border-radius: 24px;
      padding: 8px 11px;
      color: var(--white-color);
      span {
        margin-left: 12px;
      }
    }
    &__item {
      display: flex;
      align-items: center;
      padding: 12px 20px;
      margin: 12px 8px;
      div {
        margin-left: 12px;
      }
      &-active {
        background: #F7F7F7;
        border-radius: 4px;
      }
    }
  }
  .divider {
    height: 1px;
    color: var(--gray-color);
    padding: 28px 0 16px 0;
  }
}