

.login{
  &-container {
    width: 100%;
    height: 100%;
    position: relative;
    img{
      z-index: 2;
      max-width: 28px;
      position: absolute;
      top: 12px;
      left: 12px;
    }
  }
  
  &-banner{
    width: 100%;
    position: relative;
    background-image: url("/assets/images/bg-head.png");
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 200px;
    img{
      max-width: 122px;
      position: absolute;
      top: 50%;
      right: 15px;
      left: unset;
      transform: translateY(-50%);
    }
    &--text{
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      position: absolute;
      color: #fff;
      right: 20px;
      top: 54px;
    }
  }

  &-center{
    margin: auto;
    background-color: #fff;
    max-width: 450px;
    margin-top: -14px;
    z-index: 2;
    padding: 40px;
    border-radius: 20px 20px 0 0;
    position: relative;
    padding: 32px;
    padding-top: 20px;
    #login {
      margin-top: 20px;
      .ant-form-item-explain-error {
        text-align: center;
      }
    }

    .ant-tabs-nav {
      display: none;
    }

    input {
      border: 1px solid #ad0419;
      border-radius: 8px;
      text-align: start;
      &::placeholder { 
        color: #757575;
        opacity: 1; /* Firefox */
      }

    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .ant-btn-primary:hover, .ant-btn-primary:focus{
    background-color: #ad0419;
  }
  .ant-btn-primary:hover, .ant-btn-primary:focus{
    background-color: #ad0419;
  }
  &__title{
    font-weight: 500;
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 12px;
    color: #670001;
  }
  &__desc{
      font-size: 15px;
      color: #959595;
  }
  &__subTitle{
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #616161;
  }
  &__input{
    text-align: center;
    padding-left: 32px !important;
    &-phone{
      padding-left: 60px !important;
    }
    .ant-input-suffix {
      display: none;
    }
    &__icon{
      position: relative;
      .top-3 {
        svg {
          top: 36px;
        }  
      }
      svg{
        position: absolute;
        color: #ad0419;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        left: 8px;
      }
      &-phone{
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
        left: 12px;
        display: flex;
        align-items: center;
        svg{
          transform: unset;
        }
        span{
          font-size: 16px;
          padding-left: 6px;
          color: #ad0419;
        }
      }
      &-card{
        top: 14px !important;
      }
    }
  }
  &__button{
    background: #ad0419;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 18px;
    border: 1px solid var(--main-color) !important;
    box-sizing: border-box;
    color: var(--white-color) !important;
    width: 100%;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;  
    max-width: 210px;
    box-shadow: 1px 1px 5px 1px #ad0419;
    &:hover {
      background: #ad0419;
    }
    &-eleven {
      background: var(--main-eleven-color) !important;
      border: none !important;
      border-radius: 4px;
    }
    &-transparent {
      background: var(--main-color) !important;
      border: 1px solid var(--white-color) !important;
      border-radius: 2px;
    }
    &-transparent-blue {
      background: rgba(4, 123, 115, 0.1);
      border: 1px solid #047B73 !important;
      border-radius: 2px;
      color: #047B73 !important;
      span {
        font-weight: 400;
        line-height: 12px;
        color: #047B73 !important;
        }
    }
    &-transparent-red {
      background: var(--white-color) !important;
      border: 1px solid #ACACAC !important;
      border-radius: 2px important;
      color: #F61414 !important;
    }
    &-transparent-back {
      background: rgba(226, 107, 15, 0.1) !important;
      border: 1px solid #F17716 !important;
      border-radius: 2px !important;
      color: #F17716 !important;
    }

    span {
      color: #fff;
      font-size: 21px;
    }
  }
  &__parent{
    margin-top: 25px;
    margin-bottom: 0;
    display: flex;
    
  }
  &__forget{
    color: #047B73;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    margin-left: auto;
    text-decoration: underline;
    cursor: pointer;
  }
  &__footer{
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    margin-top: 20px;
    &--text{
      color: #ad0419;
      font-weight: 500;
      cursor: pointer;
      &:first-child{
        border-right: 1px solid #ad0419;
        padding-right: 10px;
        margin-right: 10px;
      }
    }
  }


  @media (max-height: 414.5px) {
    &-container {
      height: calc(100% + 260px);
    }
  }

  @media (max-height: 280.5px) {
    &-container {
      height: calc(100% + 390px);
    }
  } 
}