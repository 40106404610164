
 @keyframes myMove2 {
  10%    { margin-bottom: 0px ; }
  20%    { margin-bottom: 5px ; }
  30%   { margin-bottom: 10px ; }
  40%   { margin-bottom: 15px ; }
  50%   {margin-bottom: 10px ; }
  60%   {margin-bottom: 5px ; }
  70%  { margin-top: 0px ; }
  80%  { margin-top: 5px ;}
  90%  { margin-top: 5px ; }
  100%  { margin-top: 5px ; }
}
.home {
  .img-banner {
    height: 228px;
    max-width: 384px;
  }
  
 @media (max-width: 768px) {
  .img-banner  {
    max-width: 100%;
  }
 }   
  .bg-light-home {
    background: #fbfbfb;
  }
  
  .red-text {
    color: red;
  }
  
  .icon {
    width: 20px;
    height: 20px;
  }
  
  .van-notice-bar__content {
    white-space: nowrap;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    color: #cd0103;
  }
  
  .rowSlide {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 10px 10px;
  }
  
  .notify {
    background-image: linear-gradient(90deg, #cd0103, #f64841);
    color: white;
    font-size: small;
    justify-content: center;
    align-items: center;
    display: flex;
    align-content: center;
    width: 250px;
    height: 30px;
    border-radius: 30px;
  }
  
  .van-notice-bar__wrap {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
  }
  
  .ic_loud {
    margin-right: 10px;
    flex: 1;
  }
  
  .info {
    background-size: 100% 100%;
    flex-direction: column;
    display: flex;
    padding: 30px 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  
  .item {
    // width: 100%;
    background-color: #fbfbfb;
    margin: 25px 20px;
    border-radius: 20px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
  }
  
  .avatar {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .rowBetween {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    flex: 1;
    border-width: 0;
    width: 100%;
  }
  
  .gameTitle {
    color: #fbfbfb;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  .gameContent {
    color: #fbfbfb;
    font-size: 13px;
  }
  
  .logo {
    position: absolute;
    width: 100%;
    justify-content: flex-end;
    display: flex;
    height: 95px;
  }
  
  .logosrc {
    width: 120px;
    height: 85px;
    margin-right: 25px;
  }
  
  .webInfo {
    background: url('../../assets/images/info-bg.png');
    background-size: cover;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    margin-top: 20px;
  }
  
  .info-item {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .bottom {
    text-align: center;
    font-size: 13px;
    color: hsla(0, 0%, 100%, .5);
  }
  
  .info-value {
    font-weight: 600;
    color: #fbfbfb;
  }
  
  .icon {
    width: 20px;
    height: 20px;
  }
  
  .rank-banner {
    width: 100%;
  }
  
  .rankHead {
    background-color: #cd0103;
  }
  
  .rank-box {
    background-color: #fbfbfb;
    box-shadow: 0 5px 3px 2px rgba(0, 0, 0, 0.1);
    margin: 10px 20px 10px 20px;
  }
  
  .username {
    font-size: 15px;
  }
  
  .user-amount {
    color: #f2413b;
    font-weight: 600;
    width: 40%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  
  .ic-fire {
    width: 12px;
    height: 12px;
    margin-left: 2px;
  }
  
  .bonus-box {
    width: 100%;
    height: 148px;
    padding: 0.53333rem 0.42667rem 0;
    position: relative;
    z-index: 1;
  }
  
  .img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
  }
  
  .bonus-bg {
    position: absolute;
    top: 0;
    left: 4%;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: url('../../assets/images/adv-bonus.png') no-repeat 50%;
    background-size: cover;
  }
  
  .inner {
    font-family: MulticoloreFont;
    color: #ffefd8;
    font-size: 20px;
    // line-height: .53333rem;
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 32px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  
  .van-image__img {
    display: block;
    width: 100%;
    height: 148px;
  }
  
  .home-how {
    padding: 20px;
  }
  
  .how-info {
    box-shadow: 0 0.02667rem 0.48rem 0 rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 10px 0 10px 0;
  }
  
  .tit {
    font-size: 16px;
    color: #000;
    height: 1.33333rem;
    line-height: 1.33333rem;
    text-align: center;
    margin-bottom: 0.26667rem;
    font-weight: 600;
  }
  
  .item-info {
    width: 49%;
    padding: 0.26667rem;
    background: #feffff;
    border: 0.02667rem solid #dfdfdf;
    border-radius: 6px;
    margin-bottom: 0.26667rem;
    display: flex;
    margin: 4px;
    
  }
  
  .step {
    display: inline-block;
    font-size: 12px;
    padding: 0.13333rem;
    background: #dfdfdf;
    border-radius: 8px;
    font-weight: 400;
  }
  
  .step-img {
    display: block;
    width: 100%;
    height: 100%;
    align-self: center;
    margin-top: 50%;
  }
  
  .tab {
    flex-direction: row;
    display: flex;
  }
  
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    margin-top: 10px;
  }
  
  .van-image {
    position: relative;
    display: inline-block;
  }
  
  .arrow_button {
    width: 24px;
    height: 24px;
    box-shadow: 0 0.02667rem 0.48rem 0 rgba(0, 0, 0, 0.3);
    border-radius: 12px;
  }
  
  .swipe-container {
    display: flex;
    box-shadow: 0 0.02667rem 0.48rem 0 rgba(0, 0, 0, 0.3);
    height: 134px;
    align-items: center;
    border-radius: 6px;
    margin-top: 40px;
  
  }
  
  .content {
    flex: 1;
    margin: 5px 20px 5px 20px;
    justify-content: center;
  }
  
  .swipText {
    text-align: center;
    -webkit-line-clamp: 2;
  }
  .gameIcon{
    width: 60px;
    height: 60px;
  }
  .gameWrap{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    width: 100%;
    height: 100px;
  }
  .game1{
    animation: myMove1 1s infinite;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  .game2{
    animation: myMove2 1s infinite;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  @keyframes myMove1 {
    10%    { margin-top: 0px ; }
    20%    { margin-top: 5px ; }
    30%   { margin-top: 10px ; }
    40%   { margin-top: 15px ; }
    50%   {margin-top: 10px ; }
    60%   {margin-top: 5px ; }
    70%  { margin-bottom: 0px ; }
    80%  { margin-bottom: 5px ;}
    90%  { margin-bottom: 5px ; }
    100%  { margin-bottom: 5px ; }
  }
 
  .polygon{
    width: 12px;
    height: 12px;
  }
  
.banner {  // some style for the banner div
  background-color: #333;
  color: #eee;
  font-size: 4rem;
}
.div{
  height: 5rem;
  margin-bottom: 5rem;
  // opacity: 0;  // start hidden
  text-align: center;
}

// div:nth-child(1), div:nth-child(2) { 
//   animation: slideDownThrough $animation-length linear $animation-delay 1; 
// }
// div:nth-child(2) { animation-delay: ($animation-delay + ($animation-length / 3)); }
.itemdiv {
  animation: slideDownIn 2s infinite                      // animation length is 1/3 of total run time
}



@keyframes slideDownThrough {
  // slide text down and appear, then slide down and disappear
  0% { opacity: 0; }
  2% { opacity: 0; transform: translateY(4rem); }
  5% { opacity: 1; transform: translateY(0px); }
  17% { opacity: 1; transform: translateY(0px); }
	20% { opacity: 0; transform: translateY(4rem); }
	80% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes slideDownIn {
  // slide text down and appear, freeze on last frame to keep it visible
  0% { opacity: 0; }
  2% { opacity: 0; transform: translateY(4rem); }
  17% { opacity: 1; transform: translateY(0px); }
  100% { opacity: 1; transform: translateY(0px); }
}
}