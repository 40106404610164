.introduce {
  width: 100%;
  &__wrapper_1 {
    padding-top: 50px;
    padding-left: 302px;
    padding-right: 302px;
    padding-bottom: 50px;
    background-color: var(--main-intro-color);
  }
  &__orang_text {
    color: var(--orange-color) !important;
    display: contents;
  }
  &__flash {
    width: 100%;
    height: 1px;
    background-color: var(--grey-color);
    margin-bottom: 48px;
  }
  &__green_text {
    color: var(--white-color);
    display: contents;
  }
  &__white_text {
    color: var(--white-color) !important;
  }
  &__title_1 {
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--main-third-color);
    flex: none;
    order: 0;
    display: flex;
    flex-grow: 0;
    margin: 40px 0px;
  }
  &__title_2 {
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: var(--main-twelfth-color);
    display: flex;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 40px 0px;
  }
  &__wrapper_2 {
    padding-left: 302px;
    padding-right: 302px;
    padding-bottom: 50px;
    align-items: center;
    background-color: var(--main-intro-color) !important;
    justify-content: center;
    display: flex;
    position: relative;
  }
  &__wrapper_3 {
    padding-left: 302px;
    padding-right: 302px;
    padding-bottom: 50px;
    background-color: var(--main-intro-color) !important;
    position: relative;
  }
  &__wrapper_4 {
    background-color: var(--orange-color) !important;
    position: relative;
  }
  &__image_1 {
    width: 80px;
    height: 80px;
    background-color: var(--main-twelfth-color);
  }
  &__text_1 {
    position: absolute;
    bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--main-twelfth-color);
    order: 1;
    flex-grow: 0;
  }
  &__text_2 {
    font-size: 16px;
    color: var(--main-twelfth-color);
    display: flex;
    text-align: center;
    margin-bottom: 40px;
  }
  &__text_4 {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--main-twelfth-color);
  }
  &__text_5 {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--main-third-color);
    padding-bottom: 40px;
  }
  &__text_6 {
    font-size: 16px;
    color: var(--main-twelfth-color);
  }
  &__pd_top {
    padding-top: 50px;
    padding-left: 302px;
    padding-right: 302px;
  }
  &__text_3 {
    font-style: italic;
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--main-twelfth-color);
    align-items: center;
    justify-content: center;
    display: flex;
  }
  &__midle_title {
    font-size: 24px;
    color: var(--main-third-color);
  }
  &__midle_text {
    font-size: 16px;
    color: var(--main-third-color);
    padding-top: 100px;
    text-align: center;
  }
  &__green_text_bold {
    font-weight: bold;
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 44px;
    display: flex;
    letter-spacing: 0.02em;
    color: var(--main-third-color);
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  &__align_center {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  @media screen and (max-width: 768px) {
    svg {
      height: 400px !important;
    }
    &__pd_top {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    &__wrapper_1 {
      padding-top: 20px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-bottom: 20px !important;
    }
    &__wrapper_2 {
      padding-top: 20px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-bottom: 20px !important;
    }
    &__wrapper_3 {
      padding-top: 20px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-bottom: 20px !important;
    }
  }
  @media screen and (max-width: 1200px) {
    &__pd_top {
      padding-left: 200px;
      padding-right: 200px;
    }
    &__wrapper_1 {
      padding-top: 30px;
      padding-left: 202px;
      padding-right: 202px;
      padding-bottom: 30px;
    }
    &__wrapper_2 {
      padding-top: 30px;
      padding-left: 202px;
      padding-right: 202px;
      padding-bottom: 30px;
    }
    &__wrapper_3 {
      padding-top: 30px;
      padding-left: 202px;
      padding-right: 202px;
      padding-bottom: 30px;
    }
  }
  @media screen and (max-width: 1024px) {
    &__pd_top {
      padding-left: 100px;
      padding-right: 100px;
    }
    &__wrapper_1 {
      padding-top: 30px;
      padding-left: 102px;
      padding-right: 102px;
      padding-bottom: 30px;
    }
    &__wrapper_2 {
      padding-top: 30px;
      padding-left: 102px;
      padding-right: 102px;
      padding-bottom: 30px;
    }
    &__wrapper_3 {
      padding-top: 30px;
      padding-left: 102px;
      padding-right: 102px;
      padding-bottom: 30px;
    }
  }
}
