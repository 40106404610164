.notification {
  padding-bottom: 50px;
  // height: calc(100vh + 150px) !important;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 27px 20px 28px;
    background: var(--main-color);
    border: 1px solid rgba(20, 20, 50, 0.2);
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: var(--white-color);
      margin: 0;
      padding: 0;
    }
  }
  &__item {
    display: flex;
    justify-content: center;
    color: var(--white-color);
    align-items: center;
    &-right {
      margin-left: 12px;
      p {
        margin: 0;
        padding: 0;
        &:nth-child(1) {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-transform: uppercase;
        }
        &:nth-child(2) {
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
  .ant-table-thead {
    display: none;
  }
  @media (max-width:576px) {
    &__header {
      position: absolute;
      right: 0;
      background: transparent;
      border: none;
      .title {
        visibility: hidden;
      }
      .action {
        background: var(--main-color);
        padding: 8px;
        border-radius: 50%;
        p {
          display: none;
        }
      }
    }
  }
  .notif-header {
    // height: 84px;
    &__detail {
      &__rmBtn {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translate(-50%, -50%);
        font-size: 22px;
      }
    }
  }
  .notifi-detail {
    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    }
  }
  .notif-datetime {
    color: #5479BB;
  }
  .management__box-rounded-unset {
    margin-bottom: 100px;
  }
  .content-cut {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}