.ant-table-thead {
    tr {
        th {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #222222;
            background: #EEEEEE;
            text-align: center;
            &::before{
                display: none;
            }
        }
    }
}
.ant-table-row {
    td {
        text-align: left;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0px;
        background: #fff;
        text-align: center;
    }

   
}

.ant-table-container table > thead > tr:first-child th:first-child{
    border-top-left-radius: 12px
}
.ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 12px;
}

.ant-table-container table > tbody > tr:last-child {
    td:first-child{
        border-bottom-left-radius: 12px;
    }
    td:last-child{
        border-bottom-right-radius: 12px;
    }

   
}
.ant-table-container{
    border-radius: 12px 12px 0px 0px;
    padding: 0px 12px;
    
}
.ant-table{
    background-color: transparent;
}